import UfinetBox from 'components/box/UfinetBox';
import { FC } from 'react';
import { useIntl } from 'react-intl';

const WelcomePage: FC = () => {
  const intl = useIntl();
  return (
    <UfinetBox>
      <h2 className="text-center">
        {intl.formatMessage({ id: 'WELCOME.BILLING' })}
      </h2>
    </UfinetBox>
  );
};
export default WelcomePage;
