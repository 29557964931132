import React, { FC, useContext, useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { App } from '../App';
import { PrivateRoutes } from './PrivateRoutes';
import { AuthPage } from '../pages/auth/AuthPage';
import { AuthContext } from '../context/AuthContext';
import { AuthStatus } from '../context/AuthStatus';
import { authService } from '../services/api/auth/authService';
import { toast } from 'react-toastify';
import { VerificationPage } from 'pages/auth/VerificationPage';
import { useIntl } from 'react-intl';

export const PATH_AUTH = 'auth';

const AppRoutes: FC = () => {
  // const intl = useIntl();
  const { status, setStatus, token, setToken, logout, clear } =
    useContext(AuthContext);
  const prevToken = token;

  const [isSSOLoggingIn, setIsSSOLoggingIn] = useState<boolean>();

  useEffect(() => {
    authService.setUpBroadcastChannel({ onLogoutReceived: clear });

    setStatus(AuthStatus.VERIFYING);
    beginAuthentication();
  }, []);

  useEffect(() => {
    if (status === AuthStatus.LOGGED_OUT) return;

    isSSOLoggingIn === false && checkAuthentication();
  }, [isSSOLoggingIn, token]);

  const beginAuthentication = () => {
    setIsSSOLoggingIn(true);
    authService
      .initSSOLogin(setToken)
      .then(() => console.log('SSO login attempt complete'))
      .catch(() => {
        console.warn('SSO login attempt failed');
        // status !== AuthStatus.LOGGED_IN &&
        //   toast.error(intl.formatMessage({ id: 'AUTH.LOGIN.DOWN' }));
      })
      .finally(() => setIsSSOLoggingIn(false));
  };

  const checkAuthentication = () => {
    if (!token && !prevToken) {
      logout();
      return;
    }

    authService
      .isAuthenticated()
      .then((userData) => {
        setStatus(AuthStatus.LOGGED_IN, userData);
      })
      .catch(logout);
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          {status === AuthStatus.LOGGED_IN ? (
            <>
              <Route path="/*" element={<PrivateRoutes />} />
            </>
          ) : status === AuthStatus.VERIFYING ? (
            <>
              <Route path="/*" element={<VerificationPage />} />
            </>
          ) : (
            <>
              <Route path={`${PATH_AUTH}/*`} element={<AuthPage />} />
              <Route path="*" element={<Navigate to={PATH_AUTH} />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
