/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons/faHeart';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons/faTwitterSquare';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';

const Footer: FC = () => {
  return (
    <div
      className="footer p-4 d-flex flex-column flex-md-row justify-content-md-between align-items-center"
      id="kt_footer"
    >
      <div className="order-0">
        <div className="text-dark order-2 order-md-1">
          <span className="text-white fw-bold me-2">
            {new Date().getFullYear()} &copy;
          </span>
          <a href="/" className="text-white text-hover-primary">
            Facturación
          </a>
        </div>
      </div>
      <div className="order-1 d-flex flex-column flex-md-row align-items-center ">
        <div className="d-flex align-items-center">
          <a
            href="https://www.ufinet.com/cookies-policy/"
            target="_blank"
            rel="noreferrer"
          >
            Cookies Policy
          </a>
          <a
            href="https://www.ufinet.com/privacy-policy/"
            target="_blank"
            className="mx-2"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          <a
            href="https://www.ufinet.com/contact-us/"
            target="_blank"
            rel="noreferrer"
          >
            Contact Us
          </a>
          <FontAwesomeIcon className="ms-2" icon={faFacebookSquare} />
          <FontAwesomeIcon icon={faLinkedin} className="mx-2" />
          <FontAwesomeIcon icon={faTwitterSquare} />
        </div>
        <div className="order-2 d-flex align-items-center">
          <div className="d-none d-md-flex vr mx-2 " />
          <span className="text-white">Made with</span>
          <FontAwesomeIcon
            size="sm"
            icon={faHeart}
            className="mx-1 text-white"
          />
          <span className="mx-1 text-white">
            by{' '}
            <a
              href="https://www.nextdigital.es/"
              target="_blank"
              rel="noreferrer"
            >
              Next Digital
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export { Footer };
