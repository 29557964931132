import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { MasterLayout } from '../components/MasterLayout';
import { CablesAndBoxesPage } from '../pages/loadKmz/cables-boxes/CablesAndBoxesPage';
import { AreasPage } from '../pages/loadKmz/areas/AreasPage';
import { BuildingAccessPage } from '../pages/costs/building-access/BuildingAccessPage';
import { EquipmentAndConstructionPage } from '../pages/costs/equipment-construction/EquipmentAndConstructionPage';
import { InfrastructurePage } from '../pages/costs/infrastructure/InfrastructurePage';
import { EconomicModelPage } from '../pages/costs/economic-model/EconomicModelPage';
import { BillingModelPage } from '../pages/billing/billing-model/BillingModelPage';
import InvoiceDetails from '../pages/billing/InvoiceDetails';
import WelcomePage from 'pages/welcomePage/WelcomePage';
import { PATH_AUTH } from './AppRoutes';
import { useAuth } from 'context/AuthContext';
import { RequireAuth } from './AuthRouteTest';

export const PATH_HOME = '';

// export const PATH_LOAD_KMZ = 'load_kmz';
// export const PATH_CABL_BOX = 'cables_boxes';
// export const PATH_AREAS = 'areas';
// export const PATH_LOADK_KMZ_CABL_BOX = `${PATH_LOAD_KMZ}/${PATH_CABL_BOX}`;
// export const PATH_LOADK_KMZ_AREAS = `${PATH_LOAD_KMZ}/${PATH_AREAS}`;

export const PATH_BILLING = 'billing';
export const PATH_BILING_MODEL = 'invoice';

export const PATH_BILING_INVOICE = `${PATH_BILLING}/${PATH_BILING_MODEL}`;

export const routerMap = new Map<string, string>([
  [PATH_HOME, 'MENU.HOME'],
  [PATH_BILLING, 'MENU.BILLING'],
  [PATH_BILING_MODEL, 'MENU.BILLING.INVOICE'],
]);

export const routerLinkMap = new Map<string, string>([]);

const PrivateRoutes: FC = () => {
  let auth = useAuth();
  console.log('auth2222', auth);
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to home after success login */}
        <Route path={PATH_HOME} element={<WelcomePage />} />
        {/* Default route path */}
        <Route
          path={PATH_BILING_INVOICE}
          element={
            <RequireAuth>
              <BillingModelPage />
            </RequireAuth>
          }
        />
        <Route path={'*'} element={<Navigate to={PATH_HOME} replace />} />
      </Route>
    </Routes>
  );
};

export { PrivateRoutes };
