import {createSlice, PayloadAction} from "@reduxjs/toolkit";


export interface IauthState {
    token: string,
    username: string,
    fullName:string
    email: string
}


const initialState: IauthState = {
    token: '',
    username: '',
    fullName:'',
    email: ''
}


const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state: IauthState, action: PayloadAction<IauthState>) => {
            state = action.payload;
        },
        clearUser: (state: IauthState) => {
            state = initialState;
        },
        testing:(state:IauthState,action:PayloadAction<string>)=>{
            state.fullName = action.payload;
            console.log('se metio en testing')
        }
    }
});

export const getToken = (state: IauthState) => state.token;
export const getUserData = (state: IauthState) => {
    return {username: state.username, email: state.email,fullName: state.fullName}
}

export const {setUser, clearUser,testing} = authSlice.actions;

export default authSlice.reducer;