import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";


export interface ICountryStepper {
    allCountries:string[],
    country:string
}


const initialState: ICountryStepper = {
    allCountries:[],
    country:''
}


const countryStepperSlice = createSlice({
    name: 'countryStepper',
    initialState,
    reducers: {
       setCountry:(state,action:PayloadAction<string>)=>{
           state.country=action.payload
       },
        setAllCountries:(state,action:PayloadAction<string[]>)=>{
           state.allCountries = action.payload
        }
    }
});

export const {setCountry,setAllCountries} = countryStepperSlice.actions;

export const getCountry = (state:RootState) => state.countryStepper.country;

export default countryStepperSlice.reducer;
