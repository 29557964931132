import { IAuthData, initialAuthData } from 'context/AuthContext';
import { toast } from 'react-toastify';
import { NotificationType } from './notifications';

export type FetchOptions = {
  notification?: string; // Text shown on success
  notificationType?: NotificationType; // Type of success notification
  notifyError?: boolean; // Leave false to fail silently, use if notifications are manually emitted
  fileResponse?: boolean;
  authenticationData?: IAuthData;
  sendAuth?: boolean;
};
export const defaultOptionsFetch: FetchOptions = {
  fileResponse: false,
  notification: undefined,
  notificationType: 'success',
  notifyError: true,
  authenticationData: initialAuthData,
  sendAuth: true,
};

const authorizationHeaders = (
  authData?: IAuthData
): {} | { Authorization: string } => {
  return !authData?.token?.accessToken
    ? {}
    : { Authorization: `Bearer ${authData.token.accessToken}` };
};
const acceptHeaders = {
  Accept: 'application/json',
  'Access-Control-Expose-Headers': 'Content-Disposition',
};
const contentTypeHeaders = {
  'Content-Type': 'application/json',
  'Access-Control-Expose-Headers': 'Content-Disposition',
};
const acceptAndContentTypeHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Expose-Headers': 'Content-Disposition',
};
const buildHeaders = (method: string, auth?: IAuthData) => ({
  method,
  headers: { ...contentTypeHeaders, ...authorizationHeaders(auth) },
});
const buildHeadersWithBody = (
  method: string,
  body: FormData,
  auth?: IAuthData
) => ({
  method,
  headers: { ...acceptHeaders, ...authorizationHeaders(auth) },
  body,
});
const buildHeadersWithJsonBody = (
  method: string,
  body: string,
  auth?: IAuthData
) => ({
  method,
  headers: { ...acceptAndContentTypeHeaders, ...authorizationHeaders(auth) },
  body,
});

const manageResponse =
  (options: FetchOptions = defaultOptionsFetch) =>
  async (response: Response) => {
    if (!response.ok) {
      const errorObj = await response.json();

      // eslint-disable-next-line no-throw-literal
      throw { ...errorObj, statusCode: response.status };
    } else {
      if (options.notification) {
        switch (options.notificationType) {
          case 'success':
            toast.success(options.notification);
            break;
          case 'error':
            toast.error(options.notification);
            break;
          case 'warn':
            toast.warn(options.notification);
            break;
          default:
            toast.info(options.notification);
        }
      }
      return options.fileResponse ? response : response.json().catch(() => '');
    }
  };

function manageError(error: any, options: FetchOptions) {
  const message = [error.message, error.errors].join('. ');
  const { statusCode } = error;

  if (statusCode === 400) {
    options.notifyError && toast.warn(message);
  } else if ([401, 403].includes(statusCode)) {
    options.notifyError && toast.error(message);
    options.authenticationData?.logout();
  } else if (error.statusCode > 400 && error.statusCode < 600) {
    options.notifyError && toast.error(message);
  }
  throw error;
}

function processFetch(
  promise: Promise<Response>,
  options: FetchOptions = defaultOptionsFetch
) {
  return promise
    .then(manageResponse(options))
    .catch((err) => manageError(err, options));
}

export const FetchAPI = {
  get: async (url: string, options: FetchOptions = defaultOptionsFetch) =>
    await processFetch(
      fetch(
        url,
        buildHeaders(
          'GET',
          options.sendAuth ? options.authenticationData : undefined
        )
      ),
      options
    ),
  post: async (
    url: string,
    data: Object,
    options: FetchOptions = defaultOptionsFetch
  ) =>
    await processFetch(
      fetch(
        url,
        buildHeadersWithJsonBody(
          'POST',
          JSON.stringify(data),
          options.sendAuth ? options.authenticationData : undefined
        )
      ),
      options
    ),
  post_formdata: async (
    url: string,
    body: FormData,
    options: FetchOptions = defaultOptionsFetch
  ) =>
    await processFetch(
      fetch(
        url,
        buildHeadersWithBody(
          'POST',
          body,
          options.sendAuth ? options.authenticationData : undefined
        )
      ),
      options
    ),
  delete: async (url: string, options: FetchOptions = defaultOptionsFetch) =>
    await processFetch(
      fetch(
        url,
        buildHeaders(
          'DELETE',
          options.sendAuth ? options.authenticationData : undefined
        )
      ),
      options
    ),
  deleteWithBody: async (
    url: string,
    data: Object,
    options: FetchOptions = defaultOptionsFetch
  ) =>
    await processFetch(
      fetch(
        url,
        buildHeadersWithJsonBody(
          'DELETE',
          JSON.stringify(data),
          options.sendAuth ? options.authenticationData : undefined
        )
      ),
      options
    ),
  put: async (
    url: string,
    data: Object,
    options: FetchOptions = defaultOptionsFetch
  ) =>
    await processFetch(
      fetch(
        url,
        buildHeadersWithJsonBody(
          'PUT',
          JSON.stringify(data),
          options.sendAuth ? options.authenticationData : undefined
        )
      ),
      options
    ),
};
