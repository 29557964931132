import React, { useState, useEffect, useContext } from 'react';
import InvoiceDetails from '../InvoiceDetails';
import BillingFilters from '../BillingFilters';
import Pagination from 'components/Pagination/Pagination';
import { InvoicesProps } from '../../../typins/invoices';
import { PaginationProps } from '../../../typins/pagination';
import ModalInvoices from 'components/modal/Modal';
import CircularProgress from 'components/circular-progress/CircularProgress';
import { AuthContext } from 'context/AuthContext';

import {
  UseGetSocieties,
  UsePostInvoices,
  UseQueryGetInvoices,
  UseQueryGetSocieties,
} from '../request/FetchData';

const BillingModelPage = () => {
  const [input, setInput] = useState<InvoicesProps>({
    id: '',
    startDate: '',
    endDate: '',
    pageNumber: -1,
    pageSize: '',
    invoiceId: '',
    notificationStatus: -1,
    customerName: ''
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>([]);

  const { userData } = useContext(AuthContext);
  //We get the username by the context
  const userEmail = userData?.username ?? '';

  const {
    data: QueryGetInvoices,
    refetch,
    isLoading: isLoadingInvoices,
  } = UseQueryGetInvoices(input);

  const { data: QueryGetSocieties, isLoading } =
    UseQueryGetSocieties(userEmail);

  const cbPostInvoices = (data: any) => {
    console.log('cbPostInvoices', data);
  };

  const { mutate: postInvoices } = UsePostInvoices(cbPostInvoices);

  const handleChange = (e: any) => {
    const data = {
      ...input,
      [e.target.name]: e.target.value,
    };
    setInput(data);
  };
  
  const handleClick = () => {
    //Here refecth the data and see if the state change
    QueryGetInvoices?.data.map((item: any) => {
      item.selected = false;
    });
    setSelected([]);
    return refetch();
  };

  const handleChecked = (checked: boolean) => {
    // Here we select everything on the page depending on the results is showing
    let data: any = [];
    QueryGetInvoices?.data.map((item: any) => {
      if (!item.message) {
        item.selected = checked;
        data.push(item);
      }
    });
    if (!checked) {
      setSelected([]);
    } else {
      setSelected(data);
    }
  };

  const handleSelected = (data: any) => {
    if (data.selected) {
      data.selected = false;
      const selectedItems = selected.filter((item: any) => {
        if (item.invoiceId !== data.invoiceId) return item;
      });
      setSelected(selectedItems);
      return;
    }
    //If not selected, the item is gonna be selected and depending on
    // type of message
    QueryGetInvoices?.data.map((item: any) => {
      if (item.invoiceId === data.invoiceId && !item.message) {
        item.selected = !item.selected;
        setSelected([...selected, item]);
      }
    });
  };

  const paginate = (pageNumber: number) => {
    setInput({
      ...input,
      pageNumber,
    });
  };

  const handleModal = () => {
    setShowModal(!showModal);
  };

  const sendInvoices = () => {
    setShowModal(true);
    const data = selected.map((item: any) => {
      //Here we set all the info to send the email
      return {
        invoiceId: item.invoiceId,
        partnershipId: item.partnershipId,
        notificationGroupId: item.notificationGroupId,
        sentBy: userEmail,
      };
    });
    postInvoices(data);
  };

  return (
    <div className="p-4" style={{ backgroundColor: 'white' }}>
      {showModal && (
        <ModalInvoices showModal={showModal} handleModal={handleModal} />
      )}
      <BillingFilters
        dataSelect={QueryGetSocieties}
        handleChange={handleChange}
        handleClick={handleClick}
        sendInvoices={sendInvoices}
      />
      <InvoiceDetails
        isLoading={isLoadingInvoices}
        dataInvoices={QueryGetInvoices?.data}
        handleSelected={handleSelected}
        handleChecked={handleChecked}
      />
      {QueryGetInvoices &&
        //@ts-ignore
        QueryGetInvoices.headers.totalCount > 0 && (
          <Pagination
            //@ts-ignore
            dataPerPage={QueryGetInvoices.headers.pageSize}
            //@ts-ignore
            totalCount={QueryGetInvoices.headers.totalCount}
            paginate={paginate}
            //@ts-ignore
            pageNumber={QueryGetInvoices.headers.currentPage}
          />
        )}
    </div>
  );
};
export { BillingModelPage };
