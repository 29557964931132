import { UserTypesEnum } from '../users/userTypesEnum';

export interface IAuthUser {
  valid: boolean;
  expired: boolean;
  username: string;
  authorities: AuthorityPattern[];
  user: AuthUserDetails;
}

type AuthUserDetails = {
  fullName: string;
  type: UserTypesEnum;
};

export enum AuthEvent {
  LOGIN = 'ufinet-sso-login',
  LOGOUT = 'ufinet-sso-logout',
}

export type AuthorityPattern = `${string}#${string}#${string}`;
export interface AuthorityModel {
  raw: AuthorityPattern; // auth1#auth2#auth3
  base: string;
  module: string;
  access: AuthorityModelAccess;
}
export enum AuthorityModelAccess {
  READ = 'R',
  WRITE = 'W',
  UPDATE = 'U',
  DELETE = 'D',
}

export class Authority {
  model: AuthorityModel;

  constructor(
    input:
      | { base: string; module: string; access: AuthorityModelAccess }
      | AuthorityPattern
  ) {
    if (typeof input === 'object') {
      const { base, module, access } = input;
      this.model = {
        raw: `${base}#${module}#${access}`,
        base,
        module,
        access,
      };
    } else {
      this.model = Authority.mkAuthorityModel(input);
    }
  }

  static authorityBasePortal = 'PORTAL';
  static authorityBaseAqs = 'AQS';

  static securityConfigModuleName = 'AUTH';
  static securityConfigModuleRead = this.mkReadAuthority(
    this.securityConfigModuleName
  );
  static securityConfigModuleWrite = this.mkWriteAuthority(
    this.securityConfigModuleName
  );
  static securityConfigModuleUpdate = this.mkUpdateAuthority(
    this.securityConfigModuleName
  );
  static securityConfigModuleDelete = this.mkDeleteAuthority(
    this.securityConfigModuleName
  );

  static applicationsConfigModuleName = 'APP';
  static applicationsConfigModuleRead = this.mkReadAuthority(
    this.applicationsConfigModuleName
  );
  static applicationsConfigModuleWrite = this.mkWriteAuthority(
    this.applicationsConfigModuleName
  );
  static applicationsConfigModuleUpdate = this.mkUpdateAuthority(
    this.applicationsConfigModuleName
  );
  static applicationsConfigModuleDelete = this.mkDeleteAuthority(
    this.applicationsConfigModuleName
  );

  static mkAuthorityModel(auth: AuthorityPattern): AuthorityModel {
    const authParts = auth.split('#');
    return {
      raw: auth,
      base: authParts[0] || Authority.authorityBasePortal,
      module: authParts[1] || '',
      access: (authParts[2] as AuthorityModelAccess) || '',
    };
  }

  private static mkAuthority(
    module: string,
    access: AuthorityModelAccess
  ): Authority {
    return new Authority({
      base: Authority.authorityBasePortal,
      module,
      access,
    });
  }

  static mkReadAuthority(module: string): Authority {
    return this.mkAuthority(module, AuthorityModelAccess.READ);
  }

  static mkWriteAuthority(module: string): Authority {
    return this.mkAuthority(module, AuthorityModelAccess.WRITE);
  }

  static mkUpdateAuthority(module: string): Authority {
    return this.mkAuthority(module, AuthorityModelAccess.UPDATE);
  }

  static mkDeleteAuthority(module: string): Authority {
    return this.mkAuthority(module, AuthorityModelAccess.DELETE);
  }

  // COTIZADOR
  static authorityQuotationBase = 'AQS';

  toString = (): string => this.model.raw;
  equals = (other: Authority | AuthorityPattern): boolean => {
    return typeof other === 'object'
      ? this.model.raw === other.model.raw
      : this.model.raw === other;
  };
  getBase = (): string => this.model.base;
  getModule = (): string => this.model.module;
  getAccess = (): string => this.model.access;

  private hasAccess(input: AuthorityModelAccess): boolean {
    return this.model.access === input;
  }

  canRead(): boolean {
    return this.hasAccess(AuthorityModelAccess.READ);
  }

  canWrite(): boolean {
    return this.hasAccess(AuthorityModelAccess.WRITE);
  }

  canUpdate(): boolean {
    return this.hasAccess(AuthorityModelAccess.UPDATE);
  }

  canDelete(): boolean {
    return this.hasAccess(AuthorityModelAccess.DELETE);
  }

  isEmpty(): boolean {
    return (
      !this.canRead() &&
      !this.canWrite() &&
      !this.canUpdate() &&
      !this.canDelete()
    );
  }

  static getSecurityConfigPermissions(
    roles: (Authority | AuthorityPattern)[]
  ): IPagePermissions {
    return {
      canRead:
        roles.find((r) => Authority.securityConfigModuleRead.equals(r)) !==
        undefined,
      canWrite:
        roles.find((r) => Authority.securityConfigModuleWrite.equals(r)) !==
        undefined,
      canUpdate:
        roles.find((r) => Authority.securityConfigModuleUpdate.equals(r)) !==
        undefined,
      canDelete:
        roles.find((r) => Authority.securityConfigModuleDelete.equals(r)) !==
        undefined,
    };
  }

  static getApplicationConfigPermissions(
    roles: (Authority | AuthorityPattern)[]
  ): IPagePermissions {
    return {
      canRead:
        roles.find((r) => Authority.applicationsConfigModuleRead.equals(r)) !==
        undefined,
      canWrite:
        roles.find((r) => Authority.applicationsConfigModuleWrite.equals(r)) !==
        undefined,
      canUpdate:
        roles.find((r) =>
          Authority.applicationsConfigModuleUpdate.equals(r)
        ) !== undefined,
      canDelete:
        roles.find((r) =>
          Authority.applicationsConfigModuleDelete.equals(r)
        ) !== undefined,
    };
  }

  static isEmptyPermissions(permissions: IPagePermissions): boolean {
    return (
      !permissions.canRead &&
      !permissions.canWrite &&
      !permissions.canUpdate &&
      !permissions.canDelete
    );
  }
}

export interface IPagePermissions {
  canRead: boolean;
  canWrite: boolean;
  canUpdate: boolean;
  canDelete: boolean;
}
