import { configureStore } from '@reduxjs/toolkit';
import authReducer from 'store/auth/authSlice'
import countryStepperReducer from 'store/country-stepper/countryStepperSlice'



export const store =configureStore({
    reducer:{
        auth: authReducer,
        countryStepper: countryStepperReducer,
    }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch