import React from 'react'

const Content: React.FC = ({children}) => {


  return (
    <div id='kt_content_container' className="container-fluid">
      {children}
    </div>
  )
}

export {Content}
