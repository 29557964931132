import { FC, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';

const VerificationPage: FC = () => {
  const [showLoading, setShowLoading] = useState<boolean>(false);

  useEffect(() => {
    const spinnerTimeout = setTimeout(() => setShowLoading(true), 1250);
    return () => {
      clearTimeout(spinnerTimeout);
    };
  }, []);

  return (
    <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
      {showLoading && (
        <>
          <div>
            <Spinner animation="grow"></Spinner>
          </div>
        </>
      )}
    </div>
  );
};

export { VerificationPage };
