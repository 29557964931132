import axios from 'axios';
import { UseGetToken } from './getToken';

const getBearerToken = () => {
  if (sessionStorage && sessionStorage.length > 0) {
    return sessionStorage.getItem('token');
  }
  setTimeout(() => {
    getBearerToken();
  }, 200);
};

const createAxiosInstace = () => {
  const bearerToken = getBearerToken();

  return axios.create({
    baseURL: process.env.REACT_APP_API_PREFIX,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      //@ts-ignore
      // Authorization: process.env.REACT_APP_TOKEN,
      apiKey: process.env.REACT_APP_API_KEY,
      //origin: process.env.REACT_APP_ORIGIN || window.location.hostname,
    },
  });
};

const connection = axios.create({
  baseURL: process.env.REACT_APP_API_URL_TOKEN,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: process.env.REACT_APP_TOKEN,
    //origin: process.env.REACT_APP_ORIGIN || window.location.hostname,
  },
});

const FetchGET = async (endpoint: string, data?: any) => {
  try {
    const https = createAxiosInstace();
    const response = await https.get(endpoint, data);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const FetchPOST = async (endpoint: string, data?: any) => {
  try {
    const https = createAxiosInstace();
    const response = await https.post(endpoint, data);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const FetchPOSTToken = async (endpoint: string, data?: any) => {
  try {
    const response = await connection.post(endpoint, data);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const https = createAxiosInstace();

export { FetchGET, FetchPOST, FetchPOSTToken };
export default https;
