import { FC } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

type Props = {
  labelTitle?: string;
  tooltipTitle?: string;
  tooltipWarning?: string;
  tooltipError?: string;
  requiredIcon?: boolean;
  styles?: React.CSSProperties;
};

const Label: FC<Props> = ({
  requiredIcon,
  labelTitle,
  tooltipTitle,
  tooltipWarning,
  tooltipError,
  styles: userStyles = {},
}: Props) => {
  return !labelTitle ? null : (
    <label
      style={userStyles}
      className="d-flex align-items-center fs-6 fw-bold form-label mb-2"
      aria-labelledby={labelTitle}
    >
      <span className={`${requiredIcon ? 'required' : ''}`}>{labelTitle}</span>
      {tooltipTitle && (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip className="tooltip-dark">{tooltipTitle}</Tooltip>}
        >
          <i className="fas fa-exclamation-circle ms-2 fs-7" />
        </OverlayTrigger>
      )}
      {tooltipWarning && (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip className="tooltip-dark">{tooltipWarning}</Tooltip>}
        >
          <i
            style={{ color: '#ffc700' }}
            className="fas fa-exclamation-triangle ms-2 fs-7"
          />
        </OverlayTrigger>
      )}
      {tooltipError && (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip className="tooltip-dark">{tooltipError}</Tooltip>}
        >
          <i
            style={{ color: '#f1416c' }}
            className="fas fa-exclamation-triangle ms-2 fs-7"
          />
        </OverlayTrigger>
      )}
    </label>
  );
};

export { Label };
