import React from 'react';
import styled from 'styled-components';

interface Props {
  dataSelect?: [
    {
      partnershipId: string;
      partnershipName: string;
    }
  ];
  handleChange: (e: any) => void;
  handleClick: () => void;
  sendInvoices: () => void;
}

const statesInvoices = [
  { id: 0, state: 'No enviada' },
  { id: 1, state: 'Enviando' },
  { id: 2, state: 'Enviada' },
  { id: 3, state: 'Error al enviar' },
  { id: 4, state: 'Reenviando' },
  { id: 5, state: 'Reenviada' },
];

const BillingFilters = ({
  dataSelect,
  handleChange,
  handleClick,
  sendInvoices,
}: Props) => {
  return (
    <ContentContainer>
      <Container className="pt-3 pb-3">
        <div>
          <label className="form-label">Entidad jurídica</label>
          <select
            className="form-select"
            aria-label="Default select example"
            name="id"
            onChange={handleChange}
          >
            <option></option>
            {dataSelect &&
              dataSelect.length &&
              dataSelect.map((item) => (
                <option
                  key={item.partnershipId}
                  value={item.partnershipId}
                >{`${item.partnershipName}`}</option>
              ))}
          </select>
        </div>
        <ContainerInput className="">
          <label className="form-label">Fecha inicial</label>
          <input
            onChange={handleChange}
            name="startDate"
            type="date"
            className="form-control"
            id="exampleFormControlInput1"
          />
        </ContainerInput>
        <ContainerInput className="">
          <label className="form-label">Fecha final</label>
          <input
            onChange={handleChange}
            type="date"
            name="endDate"
            className="form-control"
            id="exampleFormControlInput1"
          />
        </ContainerInput>

        <ContainerInput className="">
          <label className="form-label">Id factura</label>
          <input
            onChange={handleChange}
            type="text"
            name="invoiceId"
            className="form-control"
            id="exampleFormControlInput1"
            placeholder="Id factura"
          />
        </ContainerInput>

        <ContainerInput className="">
          <label className="form-label">Cliente</label>
          <input
            onChange={handleChange}
            type="text"
            name="customerName"
            className="form-control"
            id="exampleFormControlInput1"
            placeholder="Cliente"
          />
        </ContainerInput>

        <ContainerSelect>
          <label className="form-label">Estado</label>
          <select
            onChange={handleChange}
            className="form-select"
            name="notificationStatus"
            aria-label=".form-select-lg example"
          >
            <option></option>
            {statesInvoices &&
              statesInvoices.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.state}
                </option>
              ))}
          </select>
        </ContainerSelect>
        <ContainerSelect>
          <label className="form-label">Resultados</label>
          <select
            onChange={handleChange}
            className="form-select"
            name="pageSize"
            aria-label=".form-select-lg example"
          >
            <option></option>
            <option>10</option>
            <option>20</option>
            <option>50</option>
          </select>
        </ContainerSelect>
        <ContainerButton>
          <button
            type="button"
            onClick={handleClick}
            className="btn btn-primary"
          >
            Refrescar
          </button>
        </ContainerButton>
      </Container>
      <ContainerButton>
        <button
          type="button"
          onClick={sendInvoices}
          className="btn btn-primary"
        >
          Enviar
        </button>
      </ContainerButton>
    </ContentContainer>
  );
};

export default BillingFilters;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Container = styled.div`
  display: flex;
`;

const ContainerInput = styled.div`
  margin-top: 5px;
  @media (min-width: 500px) {
    margin-left: 5px;
    margin-top: 0;
  }
`;
const ContainerSelect = styled.div`
  @media (min-width: 500px) {
    margin-left: 5px;
  }
`;

const ContainerButton = styled.div`
  align-self: center;
  margin-top: 5px;
  @media (min-width: 500px) {
    margin-left: 5px;
    margin-top: 25px;
  }
`;
