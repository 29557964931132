import React, { createContext } from 'react';
import { IAuthUser } from 'services/api/auth/authModel';
import { AuthStatus } from './AuthStatus';

// Initial values in auth context
export const initialAuthData: IAuthData = {
  status: AuthStatus.LOGGED_OUT,
  logout: () => {},
  setStatus: () => {},
  token: undefined,
  setToken: () => {},
  userData: undefined,
  setUser: () => {},
  authFrame: undefined,
  setAuthFrame: () => {},
  clear: () => {},
};

// Shared context for storing authentication data
export const AuthContext: React.Context<IAuthData> =
  createContext(initialAuthData);

export const useAuth = () => {
  return React.useContext(AuthContext);
};

export interface IAuthData {
  status: AuthStatus;
  logout: () => void;
  setStatus: (status: AuthStatus, user?: IAuthUser) => void;
  token?: ITokenData;
  setToken: (token: ITokenData) => void;
  userData?: IAuthUser;
  setUser: (user: IAuthUser) => void;
  authFrame?: HTMLIFrameElement;
  setAuthFrame: (iframe: HTMLIFrameElement) => void;
  clear: () => void;
}

export interface ITokenData {
  accessToken: string;
  refreshToken: string;
}
