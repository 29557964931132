import React, { FC, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { useIntl } from 'react-intl';
import { routerLinkMap, routerMap } from '../../routing/PrivateRoutes';

const DefaultTitle: FC = () => {
  const { pathname } = useLocation();
  const intl = useIntl();
  const [pathArray, setPathArray] = useState<string[]>(['']);

  useEffect(() => {
    const array = pathname.split('/');
    array.shift();
    setPathArray(array);
  }, [pathname]);

  const getSection = (id: string): string | null =>
    routerMap.get(id) ? intl.formatMessage({ id: routerMap.get(id) }) : null;
  const getLinkSection = (id: string): string =>
    (routerLinkMap.get(id) ? routerLinkMap.get(id) : '') as string;

  return (
    <div
      id="kt_page_title"
      className="page-title d-flex align-items-center flex-wrap me-3 mb-lg-0"
    >
      {/* begin::Title {intl.formatMessage({id:routerMap.get(section)})}*/}
      {
        <div className="d-flex align-items-center text-primary my-1 fs-3">
          {pathArray.map((section: string, index: number) => (
            <div key={`${section}-${index}`}>
              {getSection(section) &&
                (getLinkSection(section) && pathArray.length > 1 ? (
                  <Link
                    to={getLinkSection(section)}
                    className="hover-underline-animation"
                    key={`Title-section-${index}`}
                  >
                    {getSection(section)}
                  </Link>
                ) : (
                  <span key={`Title-section-${index}`}>
                    {getSection(section)}
                  </span>
                ))}
              {getSection(pathArray[index + 1]) &&
                index + 1 < pathArray.length && (
                  <FontAwesomeIcon
                    className="mx-2"
                    key={`Icon-section-${index}`}
                    icon={faAngleRight}
                  />
                )}
            </div>
          ))}
        </div>
      }
      {/* end::Title */}
    </div>
  );
};

export { DefaultTitle };
