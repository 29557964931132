import React, {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {AsideDefault} from 'components/common/aside/AsideDefault'
import {Footer} from 'components/common/footer/Footer'
import {HeaderWrapper} from 'components/common/header/HeaderWrapper'
import {Content} from './Content'
import {useLocation} from 'react-router-dom'
import {MenuComponent} from "assets/ts/components";
import {Toolbar} from "./toolbar/Toolbar";

const MasterLayout = () => {
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (

      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper/>
          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <Toolbar/>
            <div className='d-flex flex-column-fluid' id='kt_post'>
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
          <Footer />
        </div>
      </div>
  )
}

export {MasterLayout}
