import React from 'react';
import { toAbsoluteUrl } from 'helpers';
import { Topbar } from './Topbar';
import { KTSVG } from 'components/KTSVG';

export function HeaderWrapper() {
  return (
    <div id="kt_header" className="header align-items-stretch">
      <div className="container-fluid d-flex align-items-stretch justify-content-between">
        {/* begin::Aside mobile toggle */}
        <div
          className="d-flex align-items-center d-lg-none ms-n3 me-1"
          title="Show aside menu"
        >
          <div
            className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px me-3"
            id="kt_aside_mobile_toggle"
          >
            <KTSVG
              path="/media/icons/duotune/abstract/abs015.svg"
              className="svg-icon-2x"
            />
          </div>
        </div>
        <div className="flex-grow-1"></div>
        {/* end::Aside mobile toggle */}
        {/* begin::Logo */}
        {/* end::Logo */}

        <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
          <img
            alt="Logo"
            className="h-80px logo"
            src={toAbsoluteUrl('/media/logos/ufinet_logo_white.svg')}
          />
        </div>

        {/* begin::Wrapper */}
        <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
          {/* begin::Navbar */}
          <div className="d-flex align-items-stretch" id="kt_header_nav" />
          <div className="d-flex align-items-stretch flex-shrink-0">
            <Topbar />
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
  );
}
