import React, {
  FC,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { AuthContext } from 'context/AuthContext';
import { toAbsoluteUrl } from 'helpers';
import { onFormikTrimField } from 'helpers/UfinetFormikHelper';

import { useIntl } from 'react-intl';

import {
  UfiActionButtonHandle,
  UfinetActionButton,
} from 'components/buttons/UfinetActionButton';
import { UfinetInput } from 'components/input-fields/UfinetInput';
import { AuthStatus } from 'context/AuthStatus';
import { useFormik } from 'formik';
import { authService } from 'services/api/auth/authService';
import { internalMailRegExp } from 'services/api/users/internal/internalUserModel';
import * as Yup from 'yup';

const AuthPage: FC = () => {
  const intl = useIntl();
  const { setToken, setStatus } = useContext(AuthContext);

  const [isPopUpOpen, setIsPopUpOpen] = useState<boolean>(false);
  const [tokenReceived, setTokenReceived] = useState<boolean>(false);

  const actionBtnRef = useRef<UfiActionButtonHandle>(null);

  const isLoadingAuth = () => isPopUpOpen || tokenReceived;

  useLayoutEffect(() => {
    actionBtnRef.current?.changeActionStatus(isLoadingAuth());
  }, [isPopUpOpen, tokenReceived]);

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required(intl.formatMessage({ id: 'ERROR.REQUIRED' }))
        .email(intl.formatMessage({ id: 'ERROR.MAIL.INVALID' })),
    }),
    onSubmit: ({ email }) => handleSubmit(email),
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
  });

  const onLoginPopupOpen = () => setIsPopUpOpen(true);
  const onLoginPopupClose = () => setIsPopUpOpen(false);
  const onTokenReceived = () => setTokenReceived(true);

  const handleSubmit = (email: string): void => {
    if (isLoadingAuth()) return;

    onLoginPopupOpen();
    const loginPromise = internalMailRegExp.test(email)
      ? authService.initLoginOAuth2(
          setToken,
          formik.values.email,
          onTokenReceived,
          onLoginPopupClose
        )
      : authService.initLoginPwd(
          setToken,
          formik.values.email,
          onTokenReceived,
          onLoginPopupClose
        );
    loginPromise
      .then((userData) => {
        setStatus(AuthStatus.LOGGED_IN, userData);
      })
      .finally(onLoginPopupClose);
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="container h-100 d-flex justify-content-center auth-container-direction py-10"
    >
      <div className="auth-container-picture">
        <div />
        <img
          alt="Logo"
          className="img-fluid rounded"
          src={toAbsoluteUrl('/media/pictures/ufinet_background.jpg')}
        />
      </div>
      <div className="shadow auth-container-login">
        <img
          alt="Logo"
          className="h-100px  mb-10 logo"
          src={toAbsoluteUrl('/media/logos/ufinet_logo.svg')}
        />
        <div className="w-75">
          <div className="mb-5 w-100">
            {/* EMAIL */}
            <UfinetInput
              {...formik.getFieldProps}
              requiredIcon
              type="text"
              name="email"
              value={formik.values.email}
              error={formik.errors.email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                formik.setFieldValue('email', e.target.value)
              }
              onBlur={() => onFormikTrimField(formik, 'email', '')}
              solid={false}
              placeholder="name@example.com"
              autofocus
            />
          </div>
          <UfinetActionButton
            ref={actionBtnRef}
            className="w-100"
            content={intl.formatMessage({
              id: isLoadingAuth() ? 'AUTH.LOGGING_IN' : 'AUTH.LOGIN',
            })}
          />
        </div>
      </div>
    </form>
  );
};

export { AuthPage };
