import React, {FC, useEffect} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {KTSVG} from "../../KTSVG";
import {checkIsActive} from "helpers";

type Props = {
    to: string
    title: string
    icon?: string | JSX.Element
    hasBullet?: boolean,
    onClick?: Function,
}

const AsideMenuItem: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  hasBullet = false,
  onClick
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)

    const triggerAction = () => {
        if (onClick) {
            onClick()
        }
    }

    return (
        <div className='menu-item'>
            <Link className={clsx('menu-link without-sub', {active: isActive})} to={to} onClick={triggerAction}>
                {hasBullet && (
                    <span className='menu-bullet'>
            <span className='bullet bullet-dot'/>
          </span>
                )}
                {icon && (typeof (icon) == 'string') ? (
                    <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2'/>
          </span>
                ) : <span className='menu-icon'>
              {icon}
          </span>}
                <span className='menu-title'>{title}</span>
            </Link>
            {children}
        </div>
    )
}

export {AsideMenuItem}
