import React from 'react';
import { PaginationProps } from '../../typins/pagination';
import { Pagination } from 'react-bootstrap';

const ShowPagination = ({
  dataPerPage,
  totalCount,
  paginate,
  pageNumber,
}: PaginationProps) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalCount / dataPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <>
      <Pagination>
        <Pagination.Prev
          disabled={pageNumber === 1}
          onClick={() => paginate(pageNumber - 1)}
        >
          Anterior
        </Pagination.Prev>
        {pageNumber > 1 && (
          <Pagination.Item onClick={() => paginate(pageNumber - 1)}>
            {pageNumber - 1}
          </Pagination.Item>
        )}
        <Pagination.Item active={true} onClick={() => paginate(pageNumber)}>
          {pageNumber}
        </Pagination.Item>
        {pageNumber !== pageNumbers.length && (
          <Pagination.Item onClick={() => paginate(pageNumber + 1)}>
            {pageNumber + 1}
          </Pagination.Item>
        )}
        <Pagination.Next
          disabled={pageNumber === pageNumbers.length}
          onClick={() => paginate(pageNumber + 1)}
        >
          Siguiente
        </Pagination.Next>
      </Pagination>
    </>
  );
};

export default ShowPagination;
