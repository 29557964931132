import { Label } from 'components/forms/Label';
import React, { forwardRef } from 'react';

type UfinetInputProps = {
  className?: string;
  type: 'decimal' | 'number' | 'text' | 'search';
  name?: string;
  labelTitle?: string;
  tooltipTitle?: string;
  placeholder?: string;
  value: number | string;
  onChange?: (e: any) => any;
  onBlur?: (e: any) => any;
  error?: any;
  requiredIcon?: boolean;
  isDisabled?: boolean;
  solid?: boolean;
  autofocus?: boolean;
  style?: React.CSSProperties;
};
const UfinetInput = forwardRef(
  (
    {
      type,
      name,
      isDisabled = false,
      placeholder = '',
      requiredIcon,
      className,
      labelTitle,
      tooltipTitle,
      value,
      onChange = () => {},
      onBlur = () => {},
      error,
      solid = true,
      autofocus = false,
      style: userStyles = {},
    }: UfinetInputProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    return (
      <div className={className} style={{ ...userStyles }}>
        <Label
          labelTitle={labelTitle}
          tooltipTitle={tooltipTitle}
          requiredIcon={requiredIcon}
        />
        {type === 'decimal' ? (
          <input
            ref={ref}
            type={'number'}
            name={name}
            placeholder={placeholder}
            value={value}
            step={'any'}
            disabled={isDisabled}
            className={`form-control ${solid ? 'form-control-solid' : ''}`}
            onChange={onChange}
            onBlur={onBlur}
            autoFocus={autofocus}
          />
        ) : (
          <input
            ref={ref}
            type={type}
            name={name}
            placeholder={placeholder}
            value={value}
            disabled={isDisabled}
            className={`form-control ${solid ? 'form-control-solid' : ''}`}
            onChange={onChange}
            onBlur={onBlur}
            autoFocus={autofocus}
          />
        )}
        {error && <label className="text-danger">{error}</label>}
      </div>
    );
  }
);

export { UfinetInput };
