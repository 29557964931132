import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  forwardRef,
  MouseEventHandler,
  useImperativeHandle,
  useState,
} from 'react';
import { LoadingButton } from './LoadingButton';

type Props = {
  isDisabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  content: string | Element | JSX.Element;
  className?: string;
  icon?: IconProp;
  spinner?: boolean;
};

export type UfiActionButtonHandle = {
  changeActionStatus: (status?: boolean) => boolean;
};

const UfinetActionButton = forwardRef(
  (
    {
      isDisabled = false,
      onClick,
      content: text,
      className,
      icon,
      spinner,
    }: Props,
    ref: React.Ref<UfiActionButtonHandle>
  ) => {
    const [isLoading, setIsLoading] = useState(false);
    const getClassName = () => `btn btn-primary fs-6 ${className}`;

    const changeActionStatus = (status?: boolean) => {
      const newStatus = status ?? !isLoading;
      setIsLoading(newStatus);
      return newStatus;
    };

    useImperativeHandle(ref, () => ({
      changeActionStatus,
    }));

    return (
      <>
        {isLoading ? (
          <LoadingButton className={getClassName()} spinner={spinner} />
        ) : (
          <button
            type="submit"
            className={getClassName()}
            disabled={isDisabled}
            style={{
              cursor: isDisabled ? 'not-allowed' : 'pointer',
              pointerEvents: 'all',
            }}
            onClick={onClick}
          >
            {icon && <FontAwesomeIcon icon={icon} />}
            {icon && text && <span className={'pe-4'}></span>}
            {text}
          </button>
        )}
      </>
    );
  }
);

export { UfinetActionButton };
