import React from 'react';
import styled from 'styled-components';
import CircularProgress from '../../components/circular-progress/CircularProgress';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

interface Props {
  dataInvoices: [
    {
      invoiceId: string;
      orderId: string;
      customerName: string;
      customerId: string;
      partnershipName: string;
      partnershipId: string;
      invoicingGroupId: string;
      invoicingGroupName: string;
      notificationGroupId: string;
      partnershipCode: string;
      invoiceDate: string;
      status: string;
      sendDate: string;
      sendTo: string;
      sentBy: string;
      message: string;
      selected: boolean;
      documentType: string;
      folderPath: string;
    }
  ];
  isLoading?: boolean;
  handleSelected: (data: any) => void;
  handleChecked: (e: boolean) => void;
}

const InvoiceDetails = ({
  dataInvoices,
  isLoading,
  handleSelected,
  handleChecked,
}: Props) => {
  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div className="d-flex flex-column">
          {/* <div className="d-flex justify-content-end my-4">
        <button className="btn btn-icon btn-primary me-3">
          <FontAwesomeIcon size="lg" icon={faDownload} />
        </button>
        <button className="btn btn-icon btn-primary me-3">
          <FontAwesomeIcon size="lg" icon={faUpload} />
        </button>
        <button className="btn btn-icon btn-primary me-3">
          <FontAwesomeIcon size="lg" icon={faTrashCan} />
        </button>
        <button className="btn btn-icon btn-primary me-3">
          <FontAwesomeIcon size="lg" icon={faPlus} />
        </button>
      </div> */}
          <div className="table-responsive">
            <table className="table table-rounded border gy-4 gs-4">
              <thead>
                <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                  <th className="w-25px">
                    <div className="form-check form-check-sm form-check-custom">
                      <input
                        onChange={(e) => handleChecked(e.target.checked)}
                        className="form-check-input  widget-9-check"
                        type="checkbox"
                      />
                    </div>
                  </th>
                  <th>Documento</th>
                  <th>Tipo de documento</th>
                  <th>Pedido de venta</th>
                  <th>Cliente</th>
                  <th>Grupo</th>
                  <th>Fecha creación</th>
                  <th>Estado de envío</th>
                  <th>Fecha envío</th>
                  <th>Enviado (usuario)</th>
                  <th>Destinatarios</th>
                  <th>Adjuntos</th>
                  {/* <th>Costo puntual x metro</th>
                        <th>Costo puntual x metro MO</th> */}
                </tr>
              </thead>
              <tbody>
                {dataInvoices &&
                  dataInvoices.map((item) => (
                    //@ts-ignore
                    <TR isSelected={item.selected} key={item.invoiceId}>
                      <td>
                        <div className="form-check form-check-sm form-check-custom">
                          <input
                            checked={item.selected}
                            onChange={() => handleSelected(item)}
                            className="form-check-input widget-9-check"
                            type="checkbox"
                          />
                        </div>
                      </td>
                      <td>{item.invoiceId}</td>
                      <td>{item.documentType}</td>
                      <td>{item.orderId}</td>
                      <td>
                        <span className="badge badge-light-primary fs-7 fw-bold">
                          {item.customerName}
                        </span>
                      </td>
                      <td>{item.invoicingGroupName}</td>
                      <td>{item.invoiceDate}</td>
                      <td>
                        {item.message ? (
                          <OverlayTrigger
                            key={item.invoiceId}
                            overlay={
                              <Tooltip>
                                <strong>{item.message}</strong>.
                              </Tooltip>
                            }
                          >
                            <StatusStyled>{item.status}</StatusStyled>
                          </OverlayTrigger>
                        ) : (
                          <p>{item.status}</p>
                        )}
                      </td>
                      <td>{item.sendDate}</td>
                      <td>{item.sentBy}</td>
                      <td>{item.sendTo}</td>
                      <td>
                        <a
                          href={item.folderPath}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Ir
                        </a>
                      </td>
                      {/* <td>1,8</td> */}
                      {/* <td>0</td> */}
                    </TR>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default InvoiceDetails;

//#D6FFFF

const TR = styled.tr<{ isSelected: boolean; checked: boolean }>`
  background: ${(props) =>
    props.isSelected ? '#D6FFFF' : props.checked ? '#D6FFFF' : null};
`;

const StatusStyled = styled.p`
  font-weight: 600;
  color: #ff5252;
`;
