/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useContext } from 'react';
import { Languages } from './Languages';
import { toAbsoluteUrl } from 'helpers';
import { AuthContext } from 'context/AuthContext';
import { useIntl } from 'react-intl';
const HeaderUserMenu: FC = () => {
  //TODO get current user from store
  //const currentUser = useSelector(getUserData);
  const { userData, logout } = useContext(AuthContext);
  const name = userData?.user.fullName;
  const typeUser = userData?.user.type;
  const email = userData?.username;
  const intl = useIntl();

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary menu-responsive-width fw-bold py-4 fs-6 "
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            <img alt="Logo" src={toAbsoluteUrl('/media/avatars/blank.png')} />
          </div>

          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              {name}
              <span className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">
                {typeUser}
              </span>
            </div>
            <a href="#" className="fw-bold text-muted text-hover-primary fs-7">
              {email}
            </a>
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>

      <Languages />

      <div className="menu-item px-5">
        <a onClick={logout} className="menu-link px-5">
          {intl.formatMessage({ id: 'AUTH.LOGOUT' })}
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
