import React, {FC} from "react";
import {DefaultTitle} from "./DefaultTitle";

const Toolbar:FC=()=>{
    return(
        <div className="toolbar border-bottom d-flex align-items-center">
            <div id='kt_toolbar_container' className="container-fluid d-flex flex-stack">
                <DefaultTitle />
            </div>

        </div>
    )
}
export {Toolbar}