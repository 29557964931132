export enum UserTypesEnum {
  INTERNAL_USER = 'INTERNAL',
  EXTERNAL_USER = 'EXTERNAL',
  UNDEFINED = '',
}

export const userTypeMappings = new Map<UserTypesEnum, string>([
  [UserTypesEnum.INTERNAL_USER, 'USER.INTERNAL.NAME'],
  [UserTypesEnum.EXTERNAL_USER, 'USER.EXTERNAL.NAME'],
  [UserTypesEnum.UNDEFINED, 'USER.UNKNOWN.NAME'],
]);
