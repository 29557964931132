import { useAuth } from 'context/AuthContext';
import { FC, ReactNode } from 'react';
import { useLocation, Navigate } from 'react-router-dom';

export const hasReadAccess = (permissions: Array<string>, role: string) => {
  if (!permissions || permissions.length === 0) return false;
  const filteredRoles = permissions
    .map((permission) => permission.split('#'))
    .filter(
      (permission) =>
        permission[1] === role &&
        (permission[2] === 'R' ||
          permission[2] === 'W' ||
          permission[2] === 'U' ||
          permission[2] === 'D')
    );
  return filteredRoles.length > 0 ? true : false;
};

export const canRead = (permissions: Array<string>, role: string) => {
  const filteredRoles = permissions
    .map((permission) => permission.split('#'))
    .filter(
      (permission) => permission[1].includes(role) && permission[2] === 'R'
    );
  return filteredRoles.length > 0 ? true : false;
};

export const getRole = (location: string) => {
  if (location.includes('invoice') || location.includes('/')) {
    return 'FO01';
  }
  return 'NO-AUTHORIZED';
};

//@ts-ignore
export const RequireAuth = ({ children }) => {
  let auth = useAuth();
  let location = useLocation();
  let authorities = auth.userData ? auth.userData.authorities : [];

  if (!hasReadAccess(authorities, getRole(location.pathname))) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;
};

export const RequireRol: FC = ({ children }) => {
  let auth = useAuth();
  let location = useLocation();
  let authorities = auth.userData ? auth.userData.authorities : [];

  if (!hasReadAccess(authorities, getRole(location.pathname))) {
    return (
      <>
        <></>
      </>
    );
  }
  return <>{children}</>;
};
