import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

interface ModalProps {
  handleModal: () => void;
  showModal: boolean;
}

const ModalInvoice = ({ handleModal, showModal }: ModalProps) => {
  return (
    <Modal centered show={showModal}>
      <Modal.Header>
        <Modal.Title>Notificación de documentos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Enviando notificación(es), para ver el estado del envío por favor cierre
        esta ventana y presione refrescar
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalInvoice;
