import { useMutation, useQuery } from 'react-query';
import https, { FetchGET, FetchPOST } from '../../../utilities/https';
import { InvoicesProps } from '../../../typins/invoices';
import { PaginationProps } from '../../../typins/pagination';
import { GetToken } from 'utilities/getToken';

const UseSocieties = async (user: string | undefined) => {
  const username = user;
  try {
    const response = await FetchGET(
      `/Biller/invoicepartnerships?userId=${username}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const UseQueryGetSocieties = (userEmail: string) => {
  return useQuery(['UseQueryGetSocieties', userEmail], async () => {
    try {
      const response = await FetchGET(
        `/Biller/invoicepartnerships?userId=${userEmail}`
      );
      return response.data;
    } catch (error) {
      console.log('UseQueryGetSocieties', error);
      throw error;
    }
  });
};

const UseQueryGetInvoices = ({
  id,
  startDate,
  endDate,
  pageNumber,
  pageSize,
  invoiceId,
  notificationStatus,
  customerName
}: InvoicesProps) => {
  return useQuery(
    [
      'useGetInvoices',
      id,
      startDate,
      endDate,
      pageNumber,
      pageSize,
      invoiceId,
      notificationStatus,
      customerName,
    ],
    async () => {
      return UseInvoices({
        id,
        startDate,
        endDate,
        pageNumber,
        pageSize,
        invoiceId,
        notificationStatus,
        customerName
      });
    },
    {
      enabled: !!id && !!startDate && !!endDate,
      cacheTime: 0,
    }
  );
};

const PostInvoices = async (data: any) => {
  console.log('dataaaa', data);
  // const body = data.map((item: any) => {
  //   return {
  //     invoiceId: data.invoiceId,
  //     partnershipId: data.partnershipId,
  //     notificationGroupId: data.notificationGroupId,
  //     sentBy: 'proveedorcrm@ufinet.com',
  //   };
  // });
  // console.log('body2222', body);
  try {
    const response = await FetchPOST('Biller/sendinvoicenotifications', data);
    return response;
  } catch (error) {
    console.log('PostInvoices', error);
    throw error;
  }
};

const UseInvoices = async (data: any) => {
  const startDate = data.startDate;
  const endDate = data.endDate;
  const id = data.id;
  const pageNumber = data.pageNumber;
  const pageSize = data.pageSize;
  const invoiceId = data.invoiceId;
  const notificationStatus = data.notificationStatus;
  const customerName = data.customerName;

  try {
    const serchParms = new URLSearchParams();
    serchParms.append('invoiceParnershipId', id);
    serchParms.append('startDate', startDate);
    serchParms.append('endDate', endDate);

    if (pageNumber !== '' && pageNumber > 0) {
      serchParms.append('pageNumber', pageNumber);
    }

    if (pageSize !== '') {
      serchParms.append('pageSize', pageSize);
    }

    if (notificationStatus !== '' && notificationStatus >= 0) {
      serchParms.append('notificationStatus', notificationStatus);
    }

    if (invoiceId !== '') {
      serchParms.append('invoiceId', invoiceId);
    }

    if(customerName !== ''){
      serchParms.append('customerName', customerName);
    }

    const response = await FetchGET(
      `/Biller/invoicenotifications?${serchParms.toString()}`
    );

    if (Array.isArray(response.data)) {
      response.data = response.data.map((item: any) => {
        return { ...item, selected: false };
      });
    }

    if (response.headers) {
      //@ts-ignore
      response.headers = JSON.parse(response.headers['x-pagination']);
      const headers: PaginationProps = {
        //@ts-ignore
        dataPerPage: response.headers.pageSize ?? 0,
        //@ts-ignore
        totalCount: response.headers.totalCount ?? 0,
        //@ts-ignore
        paginate: null,
        //@ts-ignore
        pageNumber: response.headers.currentPage ?? 0,
      };
    }

    return response;
  } catch (error) {
    throw error;
  }
};

const UseGetInvoices = (callbackSuccess: any) => {
  return useMutation(UseInvoices, {
    onSuccess: (data) => {
      callbackSuccess(data);
    },
  });
};

const UseGetSocieties = (callbackSuccess: any) => {
  return useMutation(UseSocieties, {
    onSuccess: (data) => {
      callbackSuccess(data);
    },
  });
};

const UsePostInvoices = (callbackSuccess: any) => {
  return useMutation(PostInvoices, {
    onSuccess: (data) => {
      callbackSuccess(data);
    },
  });
};

export {
  UseGetSocieties,
  UseGetInvoices,
  UseQueryGetInvoices,
  UsePostInvoices,
  UseQueryGetSocieties,
};
