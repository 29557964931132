import Loading from '../utils/Loading';
import { FC, useLayoutEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

type Props = {
  className: string;
  spinner?: boolean;
};

const LoadingButton: FC<Props> = ({ className, spinner = true }: Props) => {
  const intl = useIntl();

  const spinnerRef = useRef<HTMLElement>(null);

  useLayoutEffect(() => {
    spinnerRef.current?.style.setProperty('position', 'static', 'important');
  }, []);

  return (
    <button className={className} type="button" disabled>
      {spinner && <Loading ref={spinnerRef} userClasses="spinner-grow-sm" />}
      <span>{intl.formatMessage({ id: 'LOADING_DOTS' })}</span>
    </button>
  );
};
export { LoadingButton };
