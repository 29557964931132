import clsx from 'clsx';
import React, { FC } from 'react';
import { toAbsoluteUrl } from 'helpers';
import { HeaderUserMenu } from './header-menus/HeaderUserMenu';
import { QuickLinks } from 'components/quick-links/QuicLinks';
import { KTSVG } from 'components/KTSVG';

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1';

const Topbar: FC = () => {
  return (
    <div className="d-flex align-items-stretch flex-shrink-0">
      {/* Quick links */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
      >
        {/* begin::Menu wrapper */}
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom',
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          <KTSVG
            path="/media/icons/duotune/general/gen025.svg"
            className={toolbarButtonIconSizeClass}
          />
          {/* <QuickLinks /> */}
        </div>
        {/* end::Menu wrapper */}
      </div>

      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id="kt_header_user_menu_toggle"
      >
        {/* begin::Toggle */}
        <div
          className={clsx(
            'cursor-pointer symbol',
            toolbarUserAvatarHeightClass
          )}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt="metronic" />
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}
    </div>
  );
};

export { Topbar };
