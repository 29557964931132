import { Outlet } from 'react-router-dom';

import { useContext, useEffect, useRef } from 'react';
import { I18nProvider } from './i18n/i18nProvider';
import { MasterInit } from 'layout/MasterInit';
import { LayoutProvider } from 'layout/core';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { authService } from 'services/api/auth/authService';
import { AuthContext } from 'context/AuthContext';
import { ToastContainer } from 'react-toastify';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

const App = () => {
  const { setAuthFrame } = useContext(AuthContext);

  const authFrameRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    authFrameRef.current && setAuthFrame(authFrameRef.current);
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <I18nProvider>
        <LayoutProvider>
          <Outlet />
          <MasterInit />
          <ToastContainer />
          <iframe
            ref={authFrameRef}
            hidden={true}
            title="Iframe login SSO"
            src={authService.getSSOLoginUrl()}
          />
        </LayoutProvider>
      </I18nProvider>
    </QueryClientProvider>
  );
};

export { App };
