import React from 'react'
import clsx from 'clsx'
import {useLocation} from 'react-router'
import {KTSVG} from "components/KTSVG";


type Props = {
  to: string
  title: string
  icon?: string | JSX.Element
  hasBullet?: boolean
  onClick?:Function,
  active?:boolean
}

const AsideMenuItemWithSub: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  hasBullet,
  onClick,
  active=false

}) => {
  const {pathname} = useLocation()

  const triggerAction=()=>{
    if(onClick){
      onClick()
    }
  }

  return (
    <div
      className='menu-item show menu-accordion'
      data-kt-menu-trigger='click'
      onClick={triggerAction}
    >
      <span className={clsx('menu-link', {'sub-item-selected':active})}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'/>
          </span>
        )}
        {icon && (typeof (icon) == 'string') ? (
            <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2'/>
          </span>
        ) : <span className='menu-icon'>
              {icon}
          </span>}
        <span className='menu-title'>{title}</span>
        <span className='menu-arrow'/>
      </span>
      <div className={clsx('menu-sub menu-sub-accordion')}>
        {children}
      </div>
    </div>
  )
}

export {AsideMenuItemWithSub}
