/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';
import { AsideMenuItem } from './AsideMenuItem';
import { PATH_BILING_INVOICE } from 'routing/PrivateRoutes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons/faArrowUpFromBracket';
import { faCircleDot } from '@fortawesome/free-solid-svg-icons/faCircleDot';
import { faCircleDollarToSlot } from '@fortawesome/free-solid-svg-icons/faCircleDollarToSlot';
import { faFileLines } from '@fortawesome/free-solid-svg-icons';
import { RequireRol } from 'routing/AuthRouteTest';

enum AsideMenu {
  LOAD_KMZ,
  COSTS,
  BILLING,
  NONE,
}

export function AsideMenuMain() {
  const intl = useIntl();
  const [active, setActive] = useState(AsideMenu.NONE);

  const isBillingActive = () => {
    return active == AsideMenu.BILLING;
  };
  const activeMenu = (menu: AsideMenu) => () => {
    setActive(menu);
  };
  return (
    <>
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            {intl.formatMessage({ id: 'SECTION.ADMIN' })}
          </span>
        </div>
      </div>
      {/* <AsideMenuItemWithSub
        to=""
        icon={<FontAwesomeIcon size="lg" icon={faArrowUpFromBracket} />}
        title={intl.formatMessage({ id: 'MENU.LOAD_KMZ' })}
        active={isLoadKmzActive()}
      >
        <AsideMenuItem
          to={PATH_LOADK_KMZ_CABL_BOX}
          icon={<FontAwesomeIcon size="xs" icon={faCircleDot} />}
          title={intl.formatMessage({ id: 'MENU.LOAD_KMZ.CABLES&BOXES' })}
          onClick={activeMenu(AsideMenu.LOAD_KMZ)}
        />
        <AsideMenuItem
          to={PATH_LOADK_KMZ_AREAS}
          icon={<FontAwesomeIcon size="xs" icon={faCircleDot} />}
          title={intl.formatMessage({ id: 'MENU.LOAD_KMZ.AREAS' })}
          onClick={activeMenu(AsideMenu.LOAD_KMZ)}
        />
      </AsideMenuItemWithSub> */}
      <AsideMenuItemWithSub
        to=""
        icon={<FontAwesomeIcon size="lg" icon={faFileLines} />}
        title={intl.formatMessage({ id: 'MENU.BILLING' })}
      >
        <RequireRol>
          <AsideMenuItem
            to={PATH_BILING_INVOICE}
            icon={<FontAwesomeIcon size="xs" icon={faCircleDot} />}
            title={intl.formatMessage({ id: 'MENU.BILLING.INVOICE' })}
            onClick={activeMenu(AsideMenu.BILLING)}
          />
        </RequireRol>
      </AsideMenuItemWithSub>
    </>
  );
}
