import { FC, PropsWithChildren, useEffect, useReducer } from 'react';
import { IAuthUser } from 'services/api/auth/authModel';
import {
  authService,
  localStorageAuthKey,
} from 'services/api/auth/authService';
import {
  AuthContext,
  IAuthData,
  initialAuthData,
  ITokenData,
} from './AuthContext';
import { AuthStatus } from './AuthStatus';

// Resort to session storage to persist session data
// Use Context API as middleware to parse and easily manipulate
// the data in session storage
// https://stackoverflow.com/a/62505656/9744696
const AuthProvider: FC = ({ children }: PropsWithChildren<{}>) => {
  enum ReducerTypes {
    STATUS,
    TOKEN,
    USER,
    FRAME,
    CLEAR,
  }

  function authDataReducer(
    state: IAuthData,
    { type, value }: { type: ReducerTypes; value?: any }
  ): IAuthData {
    switch (type) {
      case ReducerTypes.STATUS:
        const { status, user } = value;
        if (status === AuthStatus.LOGGED_OUT) {
          state.authFrame && authService.initLogout(state.authFrame);
          return { ...initialAuthData, authFrame: state.authFrame }; // Keep authFrame reference between logouts
        } else return { ...state, status, userData: user || state.userData };

      case ReducerTypes.TOKEN:
        const parsedToken =
          typeof value === 'string' ? JSON.parse(value) : value;
        return { ...state, token: parsedToken };

      case ReducerTypes.USER:
        return { ...state, userData: value };

      case ReducerTypes.FRAME:
        return { ...state, authFrame: value };

      case ReducerTypes.CLEAR:
        return { ...initialAuthData, authFrame: state.authFrame };

      default:
        return state;
    }
  }

  // Reducer to handle auth data
  const [authData, dispatch] = useReducer(
    authDataReducer,
    getLocalStorage(localStorageAuthKey, initialAuthData)
  );

  function setLocalStorage(key: string, value: IAuthData) {
    try {
      localStorage.setItem(
        key,
        JSON.stringify({ ...value, authFrame: undefined } as IAuthData)
      );
    } catch (e) {
      console.warn(e);
    }
  }

  function getLocalStorage(key: string, initialValue: IAuthData) {
    try {
      const localValue = localStorage.getItem(key);
      return localValue ? JSON.parse(localValue) : initialValue;
    } catch (e) {
      console.warn(e);
      return initialValue;
    }
  }

  // Update browser data when context data is changed
  useEffect(() => {
    setLocalStorage(localStorageAuthKey, authData);
  }, [authData]);

  return (
    <AuthContext.Provider
      value={{
        ...authData,
        logout: () =>
          dispatch({
            type: ReducerTypes.STATUS,
            value: { status: AuthStatus.LOGGED_OUT },
          }),
        setStatus: (status: AuthStatus, user?: IAuthUser) =>
          dispatch({ type: ReducerTypes.STATUS, value: { status, user } }),
        setToken: (token: ITokenData) =>
          dispatch({ type: ReducerTypes.TOKEN, value: token }),
        setUser: (user: IAuthUser) =>
          dispatch({ type: ReducerTypes.USER, value: user }),
        setAuthFrame: (iframe: HTMLIFrameElement) =>
          dispatch({ type: ReducerTypes.FRAME, value: iframe }),
        clear: () => dispatch({ type: ReducerTypes.CLEAR }),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
