export const onFormikChanges =
  (formik: any, field: string, orElse?: any) => (e: any) => {
    formik.setErrors({});
    formik.setFieldValue(field, e != null ? e : orElse || {});
  };
export const onFormikTextChanges =
  (formik: any, field: string, orElse?: string) => (e: any) => {
    formik.setErrors({});
    formik.setFieldValue(field, e.target.value || orElse || '');
  };
export const onFormikNumberChanges =
  (formik: any, field: string) => (e: any) => {
    formik.setErrors({});
    formik.setFieldValue(
      field,
      e.target.value != '' ? Number(e.target.value) : ''
    );
  };

export const onFormikCheckboxChanges =
  (formik: any, field: string, orElse?: boolean) => (e: any) => {
    formik.setErrors({});
    formik.setFieldValue(field, e.target.checked ?? !!orElse);
  };

export const onFormikTrimField = (
  formik: any,
  field: string,
  orElse?: string
): void => {
  const newValue = formik.values[field]?.toString().trim();
  formik.setFieldValue(field, newValue || orElse || '');
};
