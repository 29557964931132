/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { AsideMenu } from './AsideMenu';
import { PATH_HOME } from 'routing/PrivateRoutes';
import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons/faMapLocationDot';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { KTSVG } from '../../KTSVG';

const AsideDefault: FC = () => {
  return (
    <div
      id="kt_aside"
      className="aside ufinet-aside aside-hoverable"
      data-kt-drawer="true"
      data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_aside_mobile_toggle"
    >
      {/* begin::Brand */}
      <div className="aside-logo flex-column-auto" id="kt_aside_logo">
        {/* begin::Logo */}
        <Link to={PATH_HOME}>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              className="logo-minimize"
              size="3x"
              icon={faMapLocationDot}
            />
            <h1 className="logo fw-boldest my-auto ms-3 text-primary">
              Facturación
            </h1>
          </div>
        </Link>
        {/* end::Logo */}
      </div>
      {/* end::Brand */}

      {/* begin::Aside menu */}
      <div className="aside-menu flex-column-fluid">
        <AsideMenu />
      </div>
      {/* end::Aside menu */}
      <div
        id="kt_aside_toggle"
        className="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle"
        data-kt-toggle="true"
        data-kt-toggle-state="active"
        data-kt-toggle-target="body"
        data-kt-toggle-name="aside-minimize"
      >
        <KTSVG
          path={'/media/icons/duotune/arrows/arr080.svg'}
          className={'svg-icon-1 rotate-180'}
        />
      </div>
    </div>
  );
};

export { AsideDefault };
