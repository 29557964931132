import { BroadcastChannel } from 'broadcast-channel';
import { ITokenData } from 'context/AuthContext';
import { FetchAPI } from 'services/FetchAPI';
import { AuthEvent, IAuthUser } from './authModel';

export const localStorageAuthKey = 'ufinet-auth';
const loginSSOTimeout = 25000;
const authChannel = new BroadcastChannel('ufinet-auth');

export const authService = {
  getLoginPwdUrl: (): string =>
    `${process.env.REACT_APP_URL_BACK_PORTAL}auth/pwd/login`,
  getLoginOAuth2Url: (): string =>
    `${process.env.REACT_APP_URL_BACK_PORTAL}auth/oauth2/login`,
  getValidateTokenUrl: (): string =>
    `${process.env.REACT_APP_URL_BACK_PORTAL}api/auth/token/validate`,
  getSSOLoginUrl: (): string =>
    `${process.env.REACT_APP_URL_BACK_PORTAL}auth/sso/login`,
  initLoginPwd: async (
    setToken: (token: ITokenData) => void,
    username: string = '',
    onTokenReceived?: (token: ITokenData) => void,
    onFrameClose?: () => void
  ): Promise<IAuthUser> => {
    const loginUrl =
      authService.getLoginPwdUrl() +
      `${username ? `?username=${username}` : ''}`;
    return authService.initLogin(
      loginUrl,
      setToken,
      onTokenReceived,
      onFrameClose
    );
  },
  initLoginOAuth2: async (
    setToken: (token: ITokenData) => void,
    username: string = '',
    onTokenReceived?: (token: ITokenData) => void,
    onFrameClose?: () => void
    //@ts-ignore
  ): Promise<IAuthUser> => {
    try {
      const response = authService.initLogin(
        authService.getLoginOAuth2Url() +
          `${username ? `?username=${username}` : ''}`,
        setToken,
        onTokenReceived,
        onFrameClose
      );
      return response;
    } catch (error) {}
  },
  initLogin: async (
    loginUrl: string,
    setToken: (token: ITokenData) => void,
    onTokenReceived: (token: ITokenData) => void = () => {},
    onFrameClose: () => void = () => {}
  ): Promise<IAuthUser> => {
    const loginWindow = window.open(
      loginUrl,
      '_blank',
      'toolbar=0,location=0,menubar=0,popup=true'
    );

    const timer = setInterval(() => {
      if (loginWindow?.closed) {
        clearInterval(timer);
        onFrameClose();
      }
    }, 1000);

    return new Promise((res, rej) => {
      window.addEventListener(
        'message',
        (event) => {
          if (
            event.origin ===
            `${process.env.REACT_APP_URL_BACK_PORTAL}`.replace(/\/$/, '')
          ) {
            if (event.data?.eventType === 'ufinet-finish-login') {
              const token: ITokenData = event.data.eventData;
              setToken(token);
              onTokenReceived(token);
              authService.validateToken(token.accessToken).then(res);
              loginWindow && loginWindow.close();
            }
          }
        },
        false
      );
    });
  },
  initSSOLogin: async (
    setToken: (token: ITokenData) => void
  ): Promise<void> => {
    return new Promise((res, rej) => {
      window.addEventListener(
        'message',
        (event) => {
          if (
            event.origin ===
            `${process.env.REACT_APP_URL_BACK_PORTAL}`.replace(/\/$/, '')
          ) {
            if (event.data?.eventType === AuthEvent.LOGIN) {
              const receivedToken = event.data.eventData || undefined;
              setToken(receivedToken);
              res();
            } else if (event.data?.eventType === AuthEvent.LOGOUT) {
              // Logout confirmation, broadcast to tabs
              authChannel.postMessage({ type: AuthEvent.LOGOUT });
            }
          }
        },
        false
      );

      setTimeout(rej, loginSSOTimeout);
    });
  },
  setUpBroadcastChannel: (options: { onLogoutReceived?: () => void } = {}) => {
    const { onLogoutReceived } = options;
    authChannel.onmessage = (e: { type: AuthEvent }) => {
      switch (e.type) {
        case AuthEvent.LOGOUT:
          onLogoutReceived && onLogoutReceived();
          break;
        default:
          break;
      }
    };
  },
  initLogout: async (authFrame: HTMLIFrameElement): Promise<void> => {
    return new Promise((res, rej) => {
      if (!authFrame.contentWindow) rej();
      else {
        authFrame.contentWindow.postMessage(
          { eventType: AuthEvent.LOGOUT },
          '*'
        );
        res();
      }
    });
  },
  getAccessToken: (): string => {
    const auth = JSON.parse(localStorage.getItem(localStorageAuthKey) || '{}');

    const parsedToken =
      typeof auth.token === 'object'
        ? auth?.token?.accessToken
        : JSON.parse(auth.token)?.accessToken;
    return parsedToken;
  },
  validateToken: async (accessToken?: string): Promise<IAuthUser> => {
    return new Promise((res, rej) => {
      const requestToken = accessToken || authService.getAccessToken();
      if (!requestToken) rej();
      else {
        FetchAPI.post(
          authService.getValidateTokenUrl(),
          { accessToken: requestToken },
          { sendAuth: false }
        )
          .then(res)
          .catch(rej);
      }
    });
  },
  isAuthenticated: (accessToken?: string): Promise<IAuthUser> => {
    return authService.validateToken(accessToken);
  },
};
