import { RolesTypeEnum } from 'services/api/roles/rolesTypeEnum';
import { IUserResponse } from '../userModel';
import { UserTypesEnum } from '../userTypesEnum';

export interface IInternalUser {
  type: UserTypesEnum;
  email: string;
  roles: (IdAndName<number> & { type?: RolesTypeEnum })[];
}

export type IInternalUserUpdate = IInternalUser;

export type IInternalUserResponse = IUserResponse & {
  roles: IdAndName<number>[];
};

export type IdAndName<T> = {
  id: T;
  name: string;
};

export const initialInternalUser: IInternalUser = {
  type: UserTypesEnum.INTERNAL_USER,
  email: '',
  roles: [],
};

export const modelFromResponse = (
  resUser: IInternalUserResponse
): IInternalUser => ({ ...resUser });

export const internalMailRegExp = new RegExp('@ufinet\\.com$', 'i');
