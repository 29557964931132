import React, { forwardRef } from 'react';

interface ILoadingProps {
  userClasses?: string;
  userStyles?: React.CSSProperties;
}

const Loading = forwardRef(
  (
    { userClasses = '', userStyles = {} }: ILoadingProps,
    ref: React.Ref<HTMLElement>
  ) => {
    return (
      <span
        ref={ref}
        className={`position-absolute spinner-grow me-2 ${userClasses}`}
        style={{ bottom: '50%', right: '50%', ...userStyles }}
        role="status"
        aria-hidden="true"
      />
    );
  }
);

export default Loading;
