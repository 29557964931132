import React from 'react';
import * as ReactDOM from 'react-dom';
import './sass/style.scss';
import './sass/style.react.scss';
import reportWebVitals from './reportWebVitals';
import { AppRoutes } from './routing/AppRoutes';
import { Ufineti18nProvider } from './i18n/Ufineti18n';
import { Provider } from 'react-redux';
import { store } from './store/store';
import AuthProvider from 'context/AuthProvider';

ReactDOM.render(
  <>
    <Provider store={store}>
      <AuthProvider>
        <Ufineti18nProvider>
          <AppRoutes />
        </Ufineti18nProvider>
      </AuthProvider>
    </Provider>
  </>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function..
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
