import { FC } from 'react';

const UfinetBox: FC = ({ children }) => {
  return (
    <div className="d-flex flex-column h-100 container-fluid animation-transition">
      {children}
    </div>
  );
};

export default UfinetBox;
